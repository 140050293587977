import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';
import hu from 'vuetify/lib/locale/hu';

Vuetify.config.silent = process.env.NODE_ENV === 'production';

Vue.use(Vuetify);

const vuetify = new Vuetify({
  breakpoint: {
    scrollBarWidth: 16,
    thresholds: {
      xxs: 200,
      xs: 640,
      sm: 960,
      md: 1280,
      lg: 1920,
    },
  },
  lang: {
    locales: {
      hu
    },
    current: 'hu',
  },
  treeShake: true,
  defaultAssets: {
    font: {
      family: 'Libre Baskerville'
    },
  },
  theme: {
    themes: {
      light: {
        primary: {
          base: '#0C3C60',
          darken1: '#0D47A1',
        },
        secondary: '#ffae01',
        accent: '#364347',
        error: colors.red.accent3,
        muted: colors.grey.lighten4
      },
      dark: {
        primary: '#FF9001',
        secondary: '#FE7005',
        accent: '#364347',
        white: colors.grey.darken4
      }
    }
  }
});

export default vuetify;