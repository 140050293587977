import Vue from "vue";
import VueRouter from "vue-router";
import http from "@/http.js";
import store from "@/store/index.js"

import Home from "../views/Home.vue";
import HomeUsers from "@/views/HomeUsers.vue"
import Timesheet from "@/views/TimeSheet.vue"
import Login from "@/views/Login.vue"
import FirstPage from "@/views/FirstPage.vue"
import SzabadsagKeres from "@/views/SzabadsagKeres.vue"
import SzabadsagElfogadas from "@/views/SzabadsagElfogadas.vue"
import Tulora from "@/views/Tulora.vue"
import TuloraConfig from "@/views/TuloraConfig"

import Dashboard from "@/components/Dashboard.vue"
import EmptyLayout from "@/components/EmptyLayout.vue"


Vue.use(VueRouter);
async function requireAuth (to, from, next) {
  try {
    const response = await http.get('/validate', store.state.user);
    //itt csak az apikey-t kéne nézni. Majd a home-ba csak annyit kell nézni, hogy a user az 1-es id-e. Ugyan ezt kéne a Timesheet-nél is.
    if (response.status == 'OK') {
      window.vue.$store.commit('setUser', response.user)
      if(to.name == "Home" || to.name == "Timesheet" || to.name == "SzabadsagElfogadas" || to.name == "TuloraKezeles") {
        if(response.user.id == '1') {
          next();
        } else {
          router.push('/firstpage').catch(()=>{});
        }
      } else {
        next();
      }
    }
  } catch (e) {
    return
  }
}

const router = new VueRouter({
  base: process.env.BASE_URL || '',
  mode: process.env.NODE_ENV === 'production' ? 'hash' : 'hash',
  routes: [
    {path: "/login", component: Login, name: "Login", /*meta: {requiresAuth: false, title: "Bejelentkezés"}*/},
    
    {
      path: '/',
      component: Dashboard,
      children: [
        {
          path: "/",
          component: EmptyLayout,
          beforeEnter: requireAuth,
          children: [
            {
              path: '/home', 
              component: Home, 
              name: 'Home',
              beforeEnter: requireAuth,
            },
            {path: '/firstpage', component: FirstPage, name: 'FirstPage', beforeEnter: requireAuth},
            {path: '/home_users', component: HomeUsers, name: 'HomeUsers', beforeEnter: requireAuth},
            {path: '/timesheet/:id', component: Timesheet, name: 'Timesheet', beforeEnter: requireAuth},
            {path: '/szabadsagkeres', component: SzabadsagKeres, name: 'SzabadsagKeres', beforeEnter: requireAuth},
            {path: '/szabadsagelfogadas', component: SzabadsagElfogadas, name: 'SzabadsagElfogadas', beforeEnter: requireAuth},
            {path: '/tulora', component: Tulora, name: 'Tulora', beforeEnter: requireAuth},
            {path: '/tulorakezeles', component: TuloraConfig, name: 'TuloraKezeles', beforeEnter: requireAuth},
            {path: '*', component: FirstPage },
          ]
        },
      ],
    },
    
  ]
});


export default router;