import axios from 'axios';
import axiosRetry from 'axios-retry';
import Vue from "vue";

const http = axios.create({
  timeout: 9000,
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
});

http.interceptors.request.use(
  (config) => {
    config.baseURL = window.vue.$store.state.apiURL;
    config.headers.common['Session-Token'] = window.vue.$cookies.get("jelenleti_token");
    return config;
  }, (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // nincs bejelentkezve 
    if (error.response.status === 401) {
      window.vue.$router.push('/login').catch(()=>{});
    }

    // nincs jogosultság
    if (error.response.status === 403) {
      window.vue.$router.push('/firstpage').catch(()=>{});
    }

    // egyéb hiba
    if (error.response.status === 411) {
      //window.vue.$store.dispatch('setSnackBar', { msg: error.response.data.message, type: 'error' });
    }

    return Promise.reject(error.response);
  }
);

Vue.prototype.$http = http;
Vue.prototype.$axios = axios;

export default http;
