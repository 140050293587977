<template>
  <div align="center" style="margin: auto">
    <v-row dense>
      <v-col
        :cols="12"
        :lg="4"
        :md="6"
        :sm="12"
        v-for="(item, index) in intervals"
        :key="index"
        class="my-2 font"
      >
        <v-hover v-slot="{ hover }" v-if="item.id != 0">
          <v-card
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            height="170"
          >
            <p class="px-4 pt-3 my-0">Név: {{ item.name }}</p>
            <p class="px-4 my-0">Időszak: {{ item.idoszak }}</p>
            <v-container class="px-6"><v-divider></v-divider></v-container>
            <v-container class="px-6 py-0 my-0">
              <v-row>
                <v-col :cols="12" :lg="12" :md="12" :sm="12">
                  <v-btn
                    :class="{ 'show-btns': hover }"
                    elevation="0"
                    color="primary"
                    class="white--text mx-1"
                    @click="download(item)"
                    fab
                    title="Jelenléti letöltés"
                    style="background: linear-gradient(135deg, #0891b2 20%, #32bf03);"
                  >
                    <v-icon>mdi-calendar-check</v-icon>
                  </v-btn>
                  <!-- <v-btn
                    :class="{ 'show-btns': hover }"
                    v-if="item.berjegyzek != ''"
                    elevation="0"
                    color="primary"
                    class="white--text mx-1"
                    @click="downloadBerjegyzek(item)"
                    title="Bérjegyzék letöltés"
                    fab
                  >
                    <v-icon>mdi-cash-multiple</v-icon>
                  </v-btn> -->
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <!-- <v-card outlined>
        <v-data-table :headers="headers" :items="intervals">
          <template #[`item.actions`]="{ item }">
            <v-btn
              @click="download(item)"
              fab
              color="primary"
              dark
              elevation="0"
              class="rounded-sm my-2 p-2"
              title="PDF"
            >
              <v-icon>mdi-printer-outline</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    intervals: [],
    headers: [
      { text: "Hónap", value: "idoszak" },
      { text: "", value: "actions", align: "end" },
    ],
  }),
  // async beforeMount() {
  //   try {
  //     if (!this.$cookies.get("jelenleti_token")) {
  //       this.$router.push("/login");
  //     }
  //     var data = {
  //       token: this.$cookies.get("jelenleti_token"),
  //     };
  //     const response = await this.$http.post("validate/0", data);
  //     this.$store.commit("setUser", response.user);
  //     this.getItmes();
  //   } catch (e) {
  //     console.error(e);
  //   }
  // },
  async mounted() {
    this.getItmes();
  },
  methods: {
    async getItmes() {
      try {
        const response = await this.$http.post(
          "getIntervals/" + this.$store.state.user.id, {timeout: 5}
        );
        this.intervals = response.intervals;
      } catch (e) {
        console.error(e);
      }
    },
    download(item) {
      try {
        console.log(item)
        const response = this.$http.get('downloadPdf/'+item.id)
      } catch (e) {
        console.error(e);
      }
      // var filename =
      //   this.$store.state.user.name.replaceAll(" ", "").toLowerCase() +
      //   item.idoszak.toLowerCase();
      
      // const link = document.createElement("a");
      // link.href = "http://jelenleti.uninet.hu/api/tmp/" + filename + ".pdf";
      // link.setAttribute("target", "_blank");
      // link.setAttribute("download", filename + ".pdf");
      // document.body.appendChild(link);
      // link.click();
    },
    async downloadBerjegyzek(item) {
      try {
        const response = await this.$http.post("getBerjegyzek/" + item.id);
        if (response.berjegyzek != "Nincs file") {
          const link = document.createElement("a");
          link.href =
            "http://jelenleti.uninet.hu/api/tmp/" +
            response.berjegyzek +
            ".pdf";
          link.setAttribute("target", "_blank");
          link.setAttribute("download", response.berjegyzek + ".pdf");
          document.body.appendChild(link);
          link.click();
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style>
.font {
  font-family: monospace;
  font-size: large;
}
</style>