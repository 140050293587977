<template>
  <section class="d-flex bg">
    <div class="container my-auto pb-12">
      <div id="login-modal">
        <v-card elevation="24" outlined :loading="loading">
          <v-card id="header" tile color="muted" class="text-center px-sm-12">
          </v-card>

          <v-card id="body" tile elevation="0">
            <div class="pa-8 px-sm-12">
              <h2 class="mb-5">Bejelentkezés</h2>
              <v-text-field
                label="Felhasználónév"
                
                dense
                v-model="form.email"
                :error-messages="errors.email"
              />
              <v-text-field
                label="Jelszó"
                
                v-model="form.password"
                :error-messages="errors.password"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-on:keyup.enter="login"
              />
              <div class="form-actions clearfix">
                <v-btn
                  type="submit"
                  color="transparent"
                  x-large
                  elevation="1"
                  :loading="loading"
                  :disabled="loading"
                  @click="login"
                  class="my-1 mx-1"
                >
                  <v-icon class="mr-2" small>mdi-login-variant</v-icon>
                  Bejelentkezés
                </v-btn>
                <!--<v-btn
                  type="submit"
                  color="accent"
                  x-large
                  elevation="0"
                  @click="appinstall"
                  id="installApp"
                  class="my-1 mx-1"
                >
                  <v-icon class="mr-2" small>mdi-download</v-icon>
                  App install
                </v-btn>-->
              </div>
            </div>
            <v-alert class="mx-3" v-if="errors.not_found" type="error">
              {{ errors.not_found[0] }}
            </v-alert>
          </v-card>
        </v-card>
      </div>
    </div>
    <div class="text-center" style="opacity: 1">
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            <h4>Új jelszó megadása</h4>
          </v-card-title>
          <v-text-field
            label="Új jelszó megadása"
            type="password"
            v-model="password"
            class="mx-3"
            :error-messages="errors.password"
          />
          <v-text-field
            label="Új jelszó megadása"
            type="password"
            v-model="passwordAgain"
            class="mx-3"
          />
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="newPassword"> Mentés </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    form: {
      email: "",
      password: "",
    },
    loading: false,
    showPassword: false,
    errors: {
      email: "",
      password: "",
      notFound: "",
      newPass: "",
      newPassAgain: "",
    },
    dialog: false,
    password: "",
    passwordAgain: "",
  }),
  mounted() {},
  methods: {
    async login() {
      try {
        this.loading = true;

        const response = await this.$http.post("/login", this.form);
        if (Object.keys(response.errors).length > 0) {
          this.errors = response.errors;
        } else {
          if (response.user.voltmar == 0) {
            this.dialog = true;
            this.$cookies.set("jelenleti_token", response.user.apikey, 0);
            this.$store.commit("setUser", response.user);
          } else {
            this.$cookies.set("jelenleti_token", response.user.apikey, 0);
            this.$store.commit("setUser", response.user);
            this.$router.push("/firstpage");
            // this.$router.push('/firstpage');
            // if (response.user.id == 1) {
            //   this.$router.push("/home");
            // } else {
            //   this.$router.push("/home_users");
            // }
          }
        }
      } catch (e) {
        console.error(e);
      }
      this.loading = false;
    },
    async newPassword() {
      this.errors = {};
      try {
        if (this.password == this.passwordAgain && this.password.length > 6) {
          var data = {
            newPass: this.password,
            userId: this.$store.state.user.id,
          };
          const response = await this.$http.post("newPassword/0", data);
        } else {
          this.errors.password = "Nem egyezik meg a két jelszó";
        }
      } catch {
        console.error(e);
      } finally {
        this.dialog = false;
        if (this.$store.state.user.id == 1) {
          this.$router.push("/home");
        } else {
          this.$router.push("/home_users");
        }
      }
    },

    async appinstall() {
      const installApp = document.getElementById("installApp");
      if (window.matchMedia("(display-mode: standalone)").matches) {
        installApp.style.display = "none";
        
      }
      console.log(installApp)
      let deferredPrompt;
      window.addEventListener("beforeinstallprompt", (e) => {
        // e.preventDefault()
        deferredPrompt = e;
      });
      console.log(window)
      console.log(deferredPrompt);
      // if (deferredPrompt !== null) {
      //   deferredPrompt.prompt();
      //   const { outcome } = await deferredPrompt.userChoice;
      //   if (outcome === "accepted") {
      //     deferredPrompt = null;
      //     installApp.style.display = "none";
      //   }
      // }

      // installApp.addEventListener("click", async () => {

      // });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  opacity: 1;
}
section {
  background: linear-gradient(135deg, #0891b2 20%, #32bf03);
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  min-height: 100%;
  top: 0;
  left: 0;
  right: 0;

  .container {
    max-width: 600px;
    opacity: 1;
    #login-modal {
      opacity: 1;
      margin: auto;
      #header {
        #logo {
          font-size: 63px;
        }
        h1 {
          line-height: 1 !important;
          font-size: 45px !important;
          letter-spacing: -4px;
          font-weight: 600;
          & > span {
            font-size: 35px;
            letter-spacing: -3px;
            font-weight: 300;
          }
        }
      }
      #body {
        h2 {
          color: inherit;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
