import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiURL: process.env.VUE_APP_API_URL,
    // apiURL: "//jelenleti.uninet.hu/api/",
    // apiURL: "http://10.1.3.103/jelenleti/api/",
    navigation_drawer: true,
    user: {}
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {},
  modules: {},
});
