<template>
  <div>
    <p class="display-1 mt-10 white--text">Túlóra beírás</p>
    <v-row class="ma-0 pa-0">
      <v-col :cols="12" :lg="6" :md="12" :sm="12" class="ma-0 pa-1">
        <v-card class="pb-2 mb-2">
          <p class="pa-2 bgcolor">Naptár kiválasztás</p>
          <v-select
            style="width: 50%"
            class="ma-2"
            label="Naptár típus"
            hide-details=""
            :items="naptarTipus"
            v-model="aktnaptar"
          ></v-select>
        </v-card>
        <v-card >
          <v-date-picker
            first-day-of-week="1"
            :allowed-dates="allowedDates"
            width="50%"
            class="rounded-0"
            elevation="2"
            v-model="datum"
            v-if="aktnaptar == 'Túlóra'"
            color="#27bfe4"
          ></v-date-picker>
          <v-date-picker
            first-day-of-week="1"
            :allowed-dates="allowedDates2"
            width="50%"
            class="rounded-0"
            elevation="2"
            v-model="datum2"
            v-else
          ></v-date-picker>
        </v-card>
        <v-card class="mt-2">
          <p class="pa-2 bgcolor">Lecsúsztatni kívánt óra</p>
          <v-text-field
            label="Óra megadása"
            style="width: 50%"
            type="number"
            class="px-2"
            v-model="oralecsusztat"
            :error-messages="error.leora"
            :disabled="aktnaptar == 'Túlóra'"
          ></v-text-field>
          <v-btn
            absolute
            color="green"
            class="white--text mr-15"
            fab
            right
            top
            @click="lecsusztatasMentes"
            :disabled="oralecsusztat < 1"
          >
            <v-icon>mdi-run</v-icon>
          </v-btn>
          <v-data-table
            :headers="headers2"
            :items-per-page="5"
            :items="lecsusztatottorak"
            class="px-2"
          >
            <template #[`item.elfogadott`]="{ item }">
              <v-icon v-if="item.elfogadott == '0'">mdi-close</v-icon>
              <v-icon v-else>mdi-check</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col :cols="12" :lg="6" :md="12" :sm="12" class="ma-0 pa-1">
        <v-card class="pb-2 mb-2" >
          <p class="pa-2 bgcolor">Túlóra megadása</p>
          <v-row>
            <v-col :cols="12" :lg="6" :md="6" :sm="6">
              <v-btn
                absolute
                color="green"
                class="white--text mr-15"
                fab
                right
                top
                @click="TuloraMentes"
                :disabled="megjegyzes == '' && ora < 1"
              >
                <v-icon>mdi-run</v-icon>
              </v-btn>
              <v-select
                label="Túlóra típus megadása"
                class="px-2"
                :items="naptarTipus"
                v-model="akttuloraType"
              ></v-select>
              <v-text-field
                label="Óra megadása"
                type="number"
                class="px-2"
                v-model="ora"
                :error-messages="error.szam"
              ></v-text-field>
              <v-text-field
                label="Megnevezés"
                class="px-2"
                v-model="megjegyzes"
                :error-messages="error.megjegyzes"
              ></v-text-field>
            </v-col>
            <v-col :cols="12" :lg="6" :md="6" :sm="6" class="text-center mt-auto mb-auto display-2">
              {{datum}}
            </v-col>
          </v-row>
        </v-card>
        <v-card class="pb-2 mb-2" >
          <p class="pa-2 bgcolor">Túlóra keresés</p>
          <v-select
            :items="months"
            style="width: 50%"
            class="px-2"
            label="Hónapok"
            v-model="selectedMonth"
            @change="getMyTulora"
          ></v-select>
          <p class="px-2">Túlóra: {{ tulora }}</p>
          <p class="px-2">Összes lecsúsztatható: {{ lecsusztatott }}</p>
          <v-data-table
            :items="tulorak"
            :headers="headers"
            :items-per-page="5"
            class="px-2"
          >
            <template #[`item.lecsusztatott`]="{ item }">
              <v-icon v-if="item.lecsusztatott == '0'">mdi-cash</v-icon>
              <v-icon v-else>mdi-sleep</v-icon>
            </template>
            <template #[`item.elfogadott`]="{ item }">
              <v-icon v-if="item.elfogadott == '0'">mdi-close</v-icon>
              <v-icon v-else>mdi-check</v-icon>
            </template>
            <template #[`item.actions`]="{ item }">
              <v-btn
                small
                fab
                text
                elevation="0"
                class="rounded-sm m-2"
                title=""
                @click="lecsusztatas(item)"
                v-if="
                  item.lecsusztatott == 0 &&
                  item.konyvelt == 0 &&
                  item.elfogadott == 1
                "
              >
                <v-icon>mdi-cog</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <div class="text-center">
      <v-dialog v-model="modositup" width="800">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Figyelmeztetés
          </v-card-title>
          <v-card-text class="display-1 text-center">
            Biztosan szeretnéd lecsúsztatásra állítani a
            <b>{{ selectedCsusztatas.idopont }}</b> napon szerzett
            <b>{{ selectedCsusztatas.pluszora }}</b> órádat?
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="csere"> Igen </v-btn>
            <v-btn color="primary" @click="modositup = false"> Mégse </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar :color="snackbarColor" v-model="snackbar">{{
        snackbarText
      }}</v-snackbar>
    </div>
    <v-snackbar :color="snackbarColor" v-model="snackbar" class="m-0 p-0"
      ><p class="text-center ma-0 pa-0">{{ snackbarText }}</p></v-snackbar
    >
  </div>
</template>

<script>
import moment from "moment";
// import { response } from 'express';
export default {
  data: () => ({
    akttuloraType: "Túlóra",
    modositup: false,
    aktnaptar: "Túlóra",
    naptarTipus: ["Túlóra", "Lecsúsztatás"],
    snackbarColor: "green",
    snackbarText: "",
    snackbar: false,
    valasztottNap: "",
    dolgozok: [],
    months: [
      "Január",
      "Február",
      "Március",
      "Április",
      "Május",
      "Június",
      "Július",
      "Augusztus",
      "Szeptember",
      "Október",
      "November",
      "December",
    ],
    tulorak: [],
    selectedMonth: "",
    headers: [
      { text: "Dátum", value: "idopont", align: "center" },
      { text: "Óraszám", value: "pluszora", align: "center" },
      { text: "Megjegyzés", value: "megjegyzes", align: "center" },
      { text: "Típus", value: "lecsusztatott", align: "center" },
      { text: "Elfogadott", value: "elfogadott", align: "center" },
      { text: "Módosítás", value: "actions", align: "end" },
    ],
    headers2: [
      { text: "Dátum", value: "datum", align: "center" },
      { text: "Lecsúsztatott óra", value: "ora", align: "center" },
      { text: "Elfogadott", value: "elfogadott", align: "center" },
    ],
    leorak: [],
    lecsusztatott: "",
    tulora: "",
    ora: "",
    megjegyzes: "",
    datum: moment().format("YYYY-MM-DD"), //new Date("YYYY-MM-DD").toString(),
    datum2: moment().format("YYYY-MM-DD"),
    selectedCsusztatas: {},
    error: {},
    oralecsusztat: "",
    lecsusztatottorak: [],
  }),
  mounted() {
    var month = moment().format("MMMM");
    this.selectedMonth = month.charAt(0).toUpperCase() + month.slice(1);
    this.getMyTulora();
    this.getMyLecsusztatott();

    // console.log(new Date().getDay())
  },

  methods: {
    async getMyLecsusztatott() {
      try {
        const response = await this.$http.post("getMyLecsusztatott");
        this.lecsusztatottorak = response.lecsusztatottorak;
      } catch (e) {
        console.error(e);
      }
    },
    async lecsusztatasMentes() {
      try {
        this.error = {};
        var post = {
          datum: this.datum2,
          ora: this.oralecsusztat,
        };
        if (this.datum2 == "") {
          this.leora = "Nem megfelelő dátum";
        }
        console.log(this.lecsusztatott);
        console.log(this.oralecsusztat);
        if (
          parseInt(this.oralecsusztat) > parseInt(this.lecsusztatott) ||
          this.oralecsusztat < 1
        ) {
          this.error.leora = "Nem megfelelő óraszám.";
        }
        console.log(this.error);
        if (Object.keys(this.error).length <= 0) {
          const response = await this.$http.post("lecsusztatasMentes", post);
          if (response.status == "OK") {
            this.snackbarColor = "green";
            this.snackbarText = "Sikeres mentés";
          } else {
            this.snackbarColor = "red";
            this.snackbarText = "Hiba történt";
          }
          this.snackbar = true;
          this.oralecsusztat = "";
          this.getMyTulora();
          this.getMyLecsusztatott();
        }
      } catch (e) {
        console.log(e);
      }
    },
    async csere() {
      try {
        const response = await this.$http.post("tuloraCsere", {
          id: this.selectedCsusztatas.id,
        });
        if (response.status == "OK") {
          this.snackbarColor = "green";
          this.snackbarText = "Sikeres mentés";
        } else {
          this.snackbarColor = "red";
          this.snackbarText = "Hiba történt";
        }
        this.snackbar = true;
        this.ora = "";
        this.megjegyzes = "";
        this.modositup = false;
        this.getMyTulora();
      } catch (e) {
        console.error(e);
      }
    },
    lecsusztatas(item) {
      this.selectedCsusztatas = item;
      this.modositup = true;
    },
    allowedDates2(val) {
      var date = new Date();
      var hetvege = new Date(val).getDay() > 0 && new Date(val).getDay() < 6;
      var kisebbnapok = new Date(val) >= new Date();
      return hetvege && kisebbnapok;
    },
    allowedDates(val) {
      var napok = [];
      var date = new Date();
      var hetvege = new Date(val).getDay() > 0 && new Date(val).getDay() < 6;
      var kisebbnapok = new Date(val) <= new Date(); //.setHours("00", "00", "00");
      var elerheto = new Date(val);
      var elerheto_vissza = new Date(val);
      switch (new Date().getDay()) {
        case 1:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() - 4));
          break;
        case 2:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() - 4));
          break;
        case 3:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() - 2));
          break;
        case 4:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() - 2));
          break;
        case 5:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() - 2));
          break;
        case 6:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() - 3));
          break;
        case 0:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() - 4));
          break;

        default:
          break;
      }
      return hetvege && elerheto_vissza && elerheto && kisebbnapok;
    },
    async getMyTulora() {
      try {
        var month = moment().month(this.selectedMonth).format("M");
        const response = await this.$http.post("getMyTulora", { month });
        this.tulorak = response.tulorak;
        this.lecsusztatott = response.lecsusztatott;
        this.tulora = response.tulora;
      } catch (e) {
        console.error(e);
      }
    },

    async TuloraMentes() {
      try {
        this.error = {};
        if (this.ora > 8 || this.ora < 1) {
          this.error.szam = "Nem létezik ennyi túlóra egy napra.";
        }
        if (this.megjegyzes.trim().length <= 3) {
          this.error.megjegyzes = "Ez nem megfelelo megnevezés";
        }
        if (Object.keys(this.error).length == 0) {
          var post = {
            ora: this.ora,
            megjegyzes: this.megjegyzes,
            date: this.datum,
            tipus: this.akttuloraType
          };
          console.log(post);
          const response = await this.$http.post("writeTulora", post);
          if (response.mentes == "OK") {
            this.snackbarColor = "green";
            this.snackbarText = "Sikeres mentés";
          } else {
            this.snackbarColor = "red";
            this.snackbarText = response.mentes;
          }
          this.snackbar = true;
          this.ora = "";
          this.megjegyzes = "";
          this.getMyTulora();
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss">
.bgcolor {
  background-color: #27bfe4;
  color: white;
}
</style>>

