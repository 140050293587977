<template >
  <v-container fluid v-if="$store.state.user.id == 1">
    <div class="d-flex justify-end sticky to-header">
      <v-btn
        color="green"
        class="white--text mx-2 sticky-top"
        fab
        left
        x-large
        @click="$router.push('/home')"
      >
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-btn
        color="green"
        class="white--text mx-2"
        fab
        right
        x-large
        :disabled="acceptDisable"
        @click="beforePrint"
      >
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </div>
    <v-card class="my-1" dark color="transparent" outlined>
      <h1 class="mx-1 px-1">{{ dolgozo.name }}</h1>
    </v-card>

    <v-card color="transparent" dark outlined>
      <v-row class="my-3 mx-0">
        <v-col :cols="12" :lg="6" :md="12">
          <v-combobox
            label="Választott hónap"
            :items="months"
            item-text="text"
            item-value="value"
            v-model="selectedMonths"
          />
        </v-col>
        <v-col :cols="12" :lg="6" :md="12" class="align-self-center">
          <v-btn
            color="primary"
            @click="retrieval"
            :disabled="selectedMonths.value == -1"
          >
            Hónap lekérése
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-card elevation="1" v-if="marciSzabadsagok.length > 0" class="my-3">
      <v-card-title>Kivett szabadságok</v-card-title>
      <v-list-item v-for="(item, index) in marciSzabadsagok" :key="index">
        <v-list-item-content>
          <v-list-title>{{ item.mettol }}</v-list-title>
          <v-list-subtitle v-if="item.felnap == '0'"
            >Teljes nap</v-list-subtitle
          >
          <v-list-subtitle v-if="item.felnap == '1'">Fél nap</v-list-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card>
    <v-card elevation="1" :loading="loading">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="page"
        hide-default-footer
      >
        <template #[`item.nap`]="{ item }">
          {{item.nap}} 
          {{item.date}}
        </template>
        <template #[`item.erkezes`]="{ item }">
          <v-text-field
            v-model="item.erkezes"
            class="align-self-center centered-input"
            dense
            hide-details
            v-if="item.erkezes != ' ' || dolgozo.name == 'Gellér Virág'"
            :disabled="item.erkezes == '-'"
            @change="timeChange(item)"
          />
        </template>

        <template #[`item.tavozas`]="{ item }">
          <v-text-field
            v-model="item.tavozas"
            class="align-self-center centered-input"
            dense
            hide-details
            v-if="item.tavozas != ' ' || dolgozo.name == 'Gellér Virág'"
            :disabled="item.tavozas == '-'"
            @change="timeChange(item)"
          />
        </template>
        <template #[`item.tavollet_oka`]="{ item }">
          <v-select
            :items="tavolletOka"
            v-model="item.tavollet_oka"
            class="align-self-center centered-input"
            dense
            hide-details
            v-if="item.erkezes != ' '"
            @change="tavolletChange(item)"
          />
          <p v-else class="align-self-center centered-input py-auto my-auto">
            {{ item.tavollet_oka }}
          </p>
        </template>
      </v-data-table>
    </v-card>
    <v-snackbar :color="snackbarColor" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    thisYearr: "",
    printDisable: true,
    snackbar: false,
    snackbarColor: "green",
    snackbarText: "",
    page: 31,
    selectedMonths: {
      text: "",
      value: -1,
    },
    headers: [
      {
        text: "Nap",
        value: "nap",
        width: "15%",
        sortable: false,
        align: "center",
      },
      {
        text: "Érkezés",
        value: "erkezes",
        width: "5%",
        sortable: false,
        align: "center",
      },
      {
        text: "Távozás",
        value: "tavozas",
        width: "5%",
        sortable: false,
        align: "center",
      },
      {
        text: "Ledolgozott óra",
        value: "ledolgozott_ora",
        width: "5%",
        sortable: false,
        align: "center",
      },
      {
        text: "Távollét oka",
        value: "tavollet_oka",
        width: "20%",
        sortable: false,
        align: "center",
      },
      {
        text: "Megjegyzés",
        value: "megjegyzes",
        width: "20%",
        sortable: false,
        align: "center",
      },
      {
        text: "Aláírás",
        value: "alairas",
        width: "30%",
        sortable: false,
        align: "center",
      },
      {
        text: "Túlóra",
        value: "tulora",
        width: "10%",
        sortable: false,
        align: "center",
      },
    ],
    months: [
      { text: "Január", value: "01", alias: 1 },
      { text: "Február", value: "02", alias: 2 },
      { text: "Március", value: "03", alias: 3 },
      { text: "Április", value: "04", alias: 4 },
      { text: "Május", value: "05", alias: 5 },
      { text: "Június", value: "06", alias: 6 },
      { text: "Július", value: "07", alias: 7 },
      { text: "Augusztus", value: "08", alias: 8 },
      { text: "Szeptember", value: "09", alias: 9 },
      { text: "Október", value: "10", alias: 10 },
      { text: "November", value: "11", alias: 11 },
      { text: "December", value: "12", alias: 12 },
    ],
    dolgozo: {},
    items: [],
    loading: false,
    tavolletOka: ["-", "Szabadság", "Táppénz"],
    megjegyzes: [
      { text: "-", value: 0 },
      { text: "rendkívüli munkavégzés", value: 1 },
      { text: "otthoni munkavégzés", value: 2 },
    ],
    unnepek: [],
    acceptDisable: true,
    szabadsagok: [],
    marciSzabadsagok: [],
    tulorak: [],
  }),

  async mounted() {
    try {
      const month = new Date().getMonth();
      this.months.forEach((item) => {
        if (item.alias == month) {
          this.selectedMonths = item;
        }
      });
      const response = await this.$http.post(
        "getDolgozo/" + this.$route.params.id
      );
      if (response.dolgozo != null) {
        this.dolgozo = response.dolgozo;
      }
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    async print() {
      try {
        var year =
          this.selectedMonths.text == "December"
            ? moment().subtract(1, "years").format("YYYY")
            : moment().format("YYYY");
        var data = {
          dolgozo: this.dolgozo,
          month: year + "-" + this.selectedMonths.text,
          timesheet: this.items,
          monthToDay: year + "-" + this.selectedMonths.value,
        };
        const response = await this.$http.post("print/0", data, { timeout: 0 });
        const link = document.createElement("a");
        link.href = response.file;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", response.file + ".pdf");
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e);
      }
    },
    async beforePrint() {
      try {
        var year =
          this.selectedMonths.text == "December"
            ? moment().subtract(1, "years").format("YYYY")
            : moment().format("YYYY");
        var data = {
          dolgozo: this.dolgozo,
          month: year + "-" + this.selectedMonths.text,
          timesheet: this.items,
          monthToDay: year + "-" + this.selectedMonths.value,
          tulorak: this.tulorak
        };
        const response = await this.$http.post("beforePrint/0", data, {
          timeout: 0,
        });
        if (response.status == "OK") {
          this.snackbarText = "Sikeres mentés";
          this.snackbarColor = "green";
          this.snackbar = true;
        } else {
          this.snackbarText = "Hiba történt";
          this.snackbarColor = "red";
          this.snackbar = true;
        }
      } catch (e) {
        console.error(e);
      }
    },
    tavolletChange(item) {
      if (item.tavollet_oka == "Szabadság" || item.tavollet_oka == "Táppénz") {
        this.items[item.nap - 1].erkezes = "-";
        this.items[item.nap - 1].tavozas = "-";
        this.items[item.nap - 1].ledolgozott_ora = "0";
        this.items[item.nap - 1].megjegyzes = "-";
        this.items[item.nap - 1].tulora = "-";
        this.items[item.nap - 1].tavollet_oka = item.tavollet_oka;
      }
      if (item.tavollet_oka == "-") {
        this.items[item.nap - 1].tavollet_oka = item.tavollet_oka;
        this.items[item.nap - 1].erkezes = this.dolgozo.mettol;
        this.items[item.nap - 1].tavozas = this.dolgozo.meddig;
        this.items[item.nap - 1].ledolgozott_ora =
          parseInt(this.dolgozo.meddig) - parseInt(this.dolgozo.mettol);
        this.items[item.nap - 1].megjegyzes = "-";
        this.items[item.nap - 1].tulora = "-";
      }
    },
    async timeChange(item) {
      this.items[item.nap - 1].ledolgozott_ora =
        parseInt(item.tavozas) - parseInt(item.erkezes);
      if (
        parseInt(this.items[item.nap - 1].ledolgozott_ora) >
        parseInt(this.dolgozo.munkaido)
      ) {
        this.items[item.nap - 1].megjegyzes = "rendkívüli munkavégzés";
        this.items[item.nap - 1].tulora =
          parseInt(item.ledolgozott_ora) - this.dolgozo.munkaido;
      } else {
        this.items[item.nap - 1].megjegyzes = "-";
        this.items[item.nap - 1].tulora = "-";
      }
    },
    async retrieval() {
      // Ez a hónap lekérés
      try {
        this.printDisable = true;
        this.loading = true;
        var data = {};
        if (this.selectedMonths.value == "12") {
          let previousYear = moment().subtract(1, "years").year();
          data = {
            date: previousYear + "-" + this.selectedMonths.value,
          };
        } else {
          data = {
            date: moment().format("YYYY") + "-" + this.selectedMonths.value,
          };
        }

        var tuloraResPsot = {
          id: this.$route.params.id,
          date: data.date
        }
        const tuloraRes = await this.$http.post('/getOpenedTulora', tuloraResPsot);
        if(tuloraRes.van == true) {
          this.snackbarColor = "red"
          this.snackbarText = "Még van elfogadatlan túlóra!"
          this.snackbar = true;
          this.items = []
          return
        }

        const res = await this.$http.post(
          "getOpenedSzab/" + this.$route.params.id,
          data
        );
        var seged = false;
        
        if (res.opened.length == 0) {
          const response = await this.$http.post("getUnnepek/0", data);
          let unnepek = response.unnepek;
          var post = {
            user_id: this.dolgozo.id,
            date: data.date,
          };

          const resp = await this.$http.post("/getSzabadsagok", post);

          if (this.$route.params.id == 7) {
            this.marciSzabadsagok = resp.szabadsagok;
          } else {
            this.szabadsagok = resp.szabadsagok;
          }
          this.items = [];

          var thisYear =
            this.selectedMonths.value == "12"
              ? moment().subtract(1, "years").year().toString()
              : moment().format("YYYY");
          this.thisYearr = thisYear;
          var daysOfMonth = moment(
            thisYear + "-" + this.selectedMonths.value,
            "YYYY-MM"
          ).daysInMonth();

          var dataT = {
            user_id: this.$route.params.id,
            idoszak: thisYear + "-" + this.selectedMonths.text,
          };

          const timeSheet = await this.$http.post("getTimesheet/0", dataT);
          if (timeSheet.timesheet.length != 0) {
            this.items = timeSheet.timesheet;
            const resIdoszak = await this.$http.post(
              "/checkOTS",
              timeSheet.timesheet[0]
            );
            console.log(resIdoszak.idoszak.elfogadott);
            if (resIdoszak.idoszak.elfogadott == "1") {
              this.printDisable = false;
            }
          } else {
            var dataTulora = {
              user_id: this.$route.params.id,
              idoszak: thisYear + "-" + this.selectedMonths.value,
            };
            const tulorak = await this.$http.post(
              "/getTimesheetTulora",
              dataTulora
            );
            this.tulorak = tulorak.tulora
            var dolgozoMeddig = this.dolgozo.meddig;
            for (let index = 1; index <= daysOfMonth; index++) {
              var megjegyzes = "-";
              this.dolgozo.meddig = dolgozoMeddig;
              let dayOfWeek = moment(
                thisYear + "-" + this.selectedMonths.value + "-" + index
              ).day(); // 1-től indul 6 a szombar 0 a vasárnap

              let dayDate = moment(
                thisYear + "-" + this.selectedMonths.value + "-" + index
              ).format("YYYY-MM-DD"); // Itt megformálja, hogy az adott nap amit összerak dátum legyen

              // itt szabadsag obj
              // ha nem hétvégén belül azt nézni, ha nem szabi.
              let szabadsag = {};
              if (this.szabadsagok.length > 0) {
                // console.log(this.szabadsagok)
                this.szabadsagok.filter((item) => {
                  if (item.mettol == dayDate) {
                    szabadsag = item;
                    return;
                  }
                });
              }

              let tulora = {};
              if (tulorak.tulora.length > 0) {
                // console.log(this.szabadsagok)
                tulorak.tulora.filter((item) => {
                  console.log("fasz");
                  if (item.idopont == dayDate) {
                    tulora = item;
                    return;
                  }
                });
              }

              let unnep = {};
              if (unnepek.length > 0) {
                unnepek.filter((item) => {
                  if (item.unnep == dayDate) {
                    unnep = item;
                    return;
                  }
                });
              }

              if (Object.keys(tulora).length > 0) {
                console.log(dayDate + " túlóra");
                this.dolgozo.meddig =
                  parseInt(this.dolgozo.meddig) + parseInt(tulora.pluszora);
                megjegyzes = "Rendkívüli munkavégzés";
              }

              if (Object.keys(unnep).length > 0) {
                if (unnep.alias != "munkanap") {
                  let day = {
                    nap: index,
                    date: moment(this.thisYearr + "-" + this.selectedMonths.text + "-" + index).format('dddd'),
                    erkezes: " ",
                    tavozas: " ",
                    ledolgozott_ora: " ",
                    tavollet_oka: unnep.tipus,
                    megjegyzes: " ",
                    alairas: " ",
                    tulora: " ",
                  };
                  this.items.push(day);
                } else {
                  let day = {
                    nap: index,
                    date: moment(this.thisYearr + "-" + this.selectedMonths.text + "-" + index).format('dddd'),
                    erkezes: this.dolgozo.mettol,
                    tavozas: this.dolgozo.meddig,
                    ledolgozott_ora: this.dolgozo.meddig - this.dolgozo.mettol,
                    tavollet_oka: "-",
                    megjegyzes: megjegyzes,
                    alairas: "",
                    tulora:
                      this.dolgozo.meddig - this.dolgozo.mettol <=
                      this.dolgozo.munkaido
                        ? "-"
                        : this.dolgozo.meddig -
                          this.dolgozo.mettol -
                          this.dolgozo.munkaido,
                  };
                  this.items.push(day);
                }
              } else if (dayOfWeek != "6" && dayOfWeek != "0") {
                if (Object.keys(szabadsag).length > 0) {
                  let day = {
                    nap: index,
                    date: moment(this.thisYearr + "-" + this.selectedMonths.text + "-" + index).format('dddd'),
                    erkezes: "-",
                    tavozas: "-",
                    ledolgozott_ora: 0,
                    tavollet_oka: this.tavolletOka[1],
                    megjegyzes: megjegyzes,
                    alairas: "",
                    tulora:
                      this.dolgozo.meddig - this.dolgozo.mettol <=
                      this.dolgozo.munkaido
                        ? "-"
                        : this.dolgozo.meddig -
                          this.dolgozo.mettol -
                          this.dolgozo.munkaido,
                  };
                  this.items.push(day);
                } else {
                  let day = {
                    nap: index,
                    date: moment(this.thisYearr + "-" + this.selectedMonths.text + "-" + index).format('dddd'),
                    erkezes: this.dolgozo.mettol,
                    tavozas: this.dolgozo.meddig,
                    ledolgozott_ora: this.dolgozo.meddig - this.dolgozo.mettol,
                    tavollet_oka: "-",
                    megjegyzes: megjegyzes,
                    alairas: "",
                    tulora:
                      this.dolgozo.meddig - this.dolgozo.mettol <=
                      this.dolgozo.munkaido
                        ? "-"
                        : this.dolgozo.meddig -
                          this.dolgozo.mettol -
                          this.dolgozo.munkaido,
                  };
                  this.items.push(day);
                }
              } else {
                let day = {
                  nap: index,
                  date: moment(this.thisYearr + "-" + this.selectedMonths.text + "-" + index).format('dddd'),
                  erkezes: " ",
                  tavozas: " ",
                  ledolgozott_ora: " ",
                  tavollet_oka: " ",
                  megjegyzes: " ",
                  alairas: " ",
                  tulora: " ",
                };
                this.items.push(day);
              }
            }
          }
        } else {
          this.snackbarColor = "red";
          this.snackbarText =
            "Még a dolgozónak ebben a hónapban van nyitott szabadsága!!!";
          this.snackbar = true;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.acceptDisable = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.v-card {
  opacity: 0.9;
}
.centered-input >>> input {
  text-align: center;
}
.sticky {
  position: sticky;
  z-index: 5;
  top: 0;
  pointer-events: none;

  &.to-header {
    top: 64px;
  }
  & > * {
    pointer-events: auto;
  }
}
</style>