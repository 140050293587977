<template>
  <v-app style="background: linear-gradient(135deg, #0891b2 20%, #32bf03)">
    
    <v-app-bar :fixed="true" app style="background: linear-gradient(135deg, #0891b2 20%, #32bf031a)" elevation="0">
      <v-app-bar-nav-icon
        @click="
          $store.state.navigation_drawer = !$store.state.navigation_drawer
        "
        
      >
        <v-icon large>mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      
      <p class="display-1 px-3 my-auto text-uppercase"><b>Uninet Jelenléti szoftver</b></p>
      <!-- <v-row justify="end"><v-btn color="white" dark outlined right class="align-right" @click="ping">Számítógépem állapota</v-btn></v-row> -->
    </v-app-bar>
    <v-main>
      <v-navigation-drawer
        app
        v-model="$store.state.navigation_drawer"
        width="300"
        
      >
      <!-- style="background: linear-gradient(135deg, #0891b2 55%, #32bf0352)" -->
        <v-card elevation="0" flat rounded="0" >
          <v-card-title>
            <h4 class="mx-auto caption text-white">HI HI!!!</h4>
          </v-card-title>
          <v-card-text>
            <h2 class="mx-auto px-auto text-center text-uppercase">
              {{ $store.state.user.name }}
            </h2>
          </v-card-text>
        </v-card>
        <v-divider :thickness="8" class="border-opacity-100"></v-divider>
        <v-list :lines="false" density="compact" class=""  rounded>
          <v-list-item to="/firstpage" class="ma-2 rounded-lg hover">
            <v-list-item-icon class="mr-4"
              ><v-icon>mdi-home</v-icon></v-list-item-icon
            >
            <v-list-item-content
              ><v-list-item-title
                ><h5>Főoldal</h5></v-list-item-title
              ></v-list-item-content
            >
          </v-list-item>
          <v-list-item
            to="/home"
            class="ma-2 rounded-lg hover"
            v-if="$store.state.user.id == '1'"
          >
            <v-list-item-icon class="mr-4"
              ><v-icon>mdi-calendar-clock</v-icon></v-list-item-icon
            >
            <v-list-item-content
              ><v-list-item-title>
                <h5>Jelenléti ívek generálása</h5>
              </v-list-item-title></v-list-item-content
            >
          </v-list-item>
          <v-list-item to="/home_users" class="ma-2 hover">
            <v-list-item-icon class="mr-4"
              ><v-icon>mdi-account</v-icon></v-list-item-icon
            >
            <v-list-item-content
              ><v-list-item-title>
                <h5>Saját jelenlétik megtekintése</h5>
              </v-list-item-title></v-list-item-content
            >
          </v-list-item>
          <v-list-item to="/szabadsagkeres" class="ma-2 hover">
            <v-list-item-icon class="mr-4"
              ><v-icon>mdi-calendar-check</v-icon></v-list-item-icon
            >
            <v-list-item-content
              ><v-list-item-title>
                <h5>Szabadság kérés</h5>
              </v-list-item-title></v-list-item-content
            >
          </v-list-item>
          <v-list-item
            to="/szabadsagelfogadas"
            class="ma-2 hover"
            v-if="$store.state.user.id == '1'"
          >
            <v-list-item-icon class="mr-4"
              ><v-icon>mdi-check</v-icon></v-list-item-icon
            >
            <v-list-item-content
              ><v-list-item-title>
                <h5>Szabadság elfogadás</h5>
              </v-list-item-title></v-list-item-content
            >
          </v-list-item>
          <v-list-item to="/tulora" class="ma-2 hover">
            <v-list-item-icon class="mr-4"
              ><v-icon>mdi-clock</v-icon></v-list-item-icon
            >
            <v-list-item-content
              ><v-list-item-title>
                <h5>Túlóra kezelés</h5>
              </v-list-item-title></v-list-item-content
            >
          </v-list-item>
          <v-list-item
            to="/tulorakezeles"
            class="ma-2 hover"
            v-if="$store.state.user.id == '1'"
          >
            <v-list-item-icon class="mr-4"
              ><v-icon>mdi-clipboard-check</v-icon></v-list-item-icon
            >
            <v-list-item-content
              ><v-list-item-title>
                <h5>Túlóra elfogadás</h5>
              </v-list-item-title></v-list-item-content
            >
          </v-list-item>
        </v-list>
        <template v-slot:append>
          <v-list class="pa-2" :lines="false" density="compact" rounded>
            <v-list-item @click="logout" class="ma-2 rounded-lg soros">
              <v-list-item-icon class="mr-4"
                ><v-icon>mdi-logout</v-icon></v-list-item-icon
              >
              <v-list-item-content
                ><v-list-item-title>
                  <h5>Kijelentkezés</h5>
                </v-list-item-title></v-list-item-content
              >
            </v-list-item>
          </v-list>
        </template>
      </v-navigation-drawer>
      <v-container class="px-13 pb-8" fluid>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    szabError: "",
    menu: false,
    menu2: false,
    dates: [],
    mettol: new Date().toISOString().substring(0, 10),
    meddig: new Date().toISOString().substring(0, 10),
    szabadsagokCount: 1,
    dialog: false,
    szabadsagModDialog: false,
    szabadsagKeresDialog: false,
    honapok: [
      { value: "01", text: "Január" },
      { value: "02", text: "Február" },
      { value: "03", text: "Március" },
      { value: "04", text: "Április" },
      { value: "05", text: "Május" },
      { value: "06", text: "Június" },
      { value: "07", text: "Július" },
      { value: "08", text: "Augusztus" },
      { value: "09", text: "Szeptember" },
      { value: "10", text: "Október" },
      { value: "11", text: "November" },
      { value: "12", text: "December" },
    ],
    selectedMonth: [],
    lenghtRules: [
      (v) => v.length != 3 || "Pontosan csak 3 hónapot lehet kiválasztani",
    ],
    error: [],
  }),

  mounted() {},

  methods: {
    allowedDates: (val) =>
      new Date(val).getDay() > 0 && new Date(val).getDay() < 6,

    logout() {
      this.$store.state.user = {};
      this.$cookies.remove("jelenleti_token");
      this.$router.push("/login");
    },
    async requestLeave() {
      if (this.mettol > this.meddig) {
        this.szabError = "A végdátum nem lehet kisebb a kezdő dátumnál";
        return;
      }
      if (
        this.mettol < new Date().toISOString().substring(0, 10) ||
        this.meddig < new Date().toISOString().substring(0, 10)
      ) {
        this.szabError = "A mai napnál nem lehet korábbira szabadságot írni";
        return;
      }
      var post = {
        user: this.$store.state.user,
        mettol: this.mettol,
        meddig: this.meddig,
      };

      const response = await this.$http.post("szabadsagEmail/", post);
    },
    timesheetek() {
      if (this.$store.state.user.id == 1) {
        this.$router.push("home_users");
      }
    },
    timesheetGenerate() {
      if (this.$store.state.user.id == 1) {
        this.$router.push("home");
      }
    },
    async idoszakGeneralas() {
      try {
        this.error = [];
        if (this.selectedMonth.length != 3) {
          this.error = ["A kiválasztott hónapoknak pontosan 3-nak kell lennie"];
        }
        if (this.error.length == 0) {
          var names = [];
          this.selectedMonth = this.selectedMonth.sort();
          this.selectedMonth.forEach((element) => {
            this.honapok.forEach((e) => {
              if (element == e.value) {
                names.push(e.text);
              }
            });
          });
          var post = {
            honapok: this.selectedMonth,
            nevek: names,
          };
          const response = await this.$http.post("idoszakGeneralas/0", post);
          const link = document.createElement("a");
          link.href = response.file;
          link.setAttribute("target", "_blank");
          link.setAttribute("download", response.file + ".pdf");
          document.body.appendChild(link);
          link.click();
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style scoped>

</style>