<template>
  <div>
    <p class="display-1 mt-10">Túlóra elfogadás</p>
    <v-row>
      <v-col :cols="12" :lg="6" :md="12" :sm="12">
        <v-card outlined elevation="1" class="mb-2">
          <p class="pa-2 bgcolor">Plusz óra elfogadás</p>
          <v-data-table :headers="headers" :items="tulorak">
            <template #[`item.actions`]="{ item }">
              <v-icon @click="elfogadTulora(item)">mdi-check</v-icon>
            </template>
            <template #[`item.lecsusztatott`]="{ item }">
              <v-icon v-if="item.lecsusztatott == '0'">mdi-cash</v-icon>
              <v-icon v-else>mdi-sleep</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col :cols="12" :lg="6" :md="12" :sm="12">
        <v-card outlined elevation="1" class="mb-2">
          <p class="pa-2 bgcolor">Lecsúsztatás elfogadás</p>
          <v-data-table :headers="headers2" :items="lecsusz">
            <template #[`item.actions`]="{ item }">
              <v-icon @click="elfogadLecsusztat(item)">mdi-check</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar :color="snackbarColor" v-model="snackbar" class="m-0 p-0"
      ><p class="text-center ma-0 pa-0">{{ snackbarText }}</p></v-snackbar
    >
  </div>
</template>

<script>
export default {
  data: () => ({
    snackbarColor: "",
    snackbar: false,
    snackbarText: "",
    headers: [
      { text: "Név", value: "name", align: "center" },
      { text: "Mikor", value: "idopont", align: "center" },
      { text: "Mennyi", value: "pluszora", align: "center" },
      { text: "Megjegyzés", value: "megjegyzes", align: "center" },
      { text: "Típus", value: "lecsusztatott", align: "center" },
      { text: "Elfogadáas", value: "actions", align: "center" },
    ],
    headers2: [
      { text: "Név", value: "name", align: "center" },
      { text: "Mikor", value: "datum", align: "center" },
      { text: "Mennyi", value: "ora", align: "center" },
      { text: "Elfogadáas", value: "actions", align: "center" },
    ],
    tulorak: [],
    lecsusz: [],
  }),

  mounted() {
    this.getAllTulora();
    this.getAllLecsusztatott();
  },

  methods: {
    async getAllTulora() {
      try {
        const response = await this.$http.post("getAllTulora");
        this.tulorak = response.tulorak;
      } catch (e) {
        console.error(e);
      }
    },

    async getAllLecsusztatott() {
      try {
        const response = await this.$http.post("getAllLecsusztatott");
        this.lecsusz = response.lecsusztatottorak;
      } catch (e) {
        console.error(e);
      }
    },

    async elfogadLecsusztat(item) {
      try {
        const response = await this.$http.post("elfogadLecsusztat", item);
        if (response.status == "OK") {
          this.snackbarColor = "green";
          this.snackbarText = "Sikeres mentés";
        } else {
          this.snackbarColor = "red";
          this.snackbarText = "Hiba történt";
        }
        this.snackbar = true;
        this.getAllLecsusztatott();
        this.getAllTulora();
      } catch (e) {
        console.error(e);
      }
    },

    async elfogadTulora(item) {
      try {
        const response = await this.$http.post("elfogadTulora", item);
        if (response.status == "OK") {
          this.snackbarColor = "green";
          this.snackbarText = "Sikeres mentés";
        } else {
          this.snackbarColor = "red";
          this.snackbarText = "Hiba történt";
        }
        this.snackbar = true;
        this.getAllLecsusztatott();
        this.getAllTulora();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style>
</style>