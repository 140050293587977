<template>
  <v-container fluid>
    <v-row class="mb-5 justify-end px-0 d-flex sticky to-header">
      <v-btn fab class="top-0 btnhoverr mx-1" @click="addDolgozo">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn fab class="top-0 btnhoverr mx-1" @click="sornyomtat" :disabled="nyomtathato">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-row>
    <v-row dense>
      <v-col
        :cols="12"
        :lg="4"
        :md="6"
        :sm="12"
        v-for="(item, index) in dolgozok"
        :key="index"
        class="my-2"
      >
        <v-hover v-slot="{ hover }" v-if="item.id != 0" >
          <v-card
            :elevation="hover ? 12 : 2"
            :class="{ 'on-hover': hover }"
            min-height="280"            
          >
            <v-btn
              absolute
              color="green"
              class="white--text mx-11"
              fab
              right
              top
              small
              @click="modifyDolgozo(item)"
            >
              <v-icon>mdi-cog</v-icon>
            </v-btn>
            <v-btn
              absolute
              color="green"
              class="white--text"
              fab
              right
              top
              small
              @click="deleteDolgozo(item)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <v-row class="ma-0 pa-0">
              <v-col
                :cols="12"
                :lg="6"
                :md="6"
                :sm="6"
                class="d-flex justify-start pt-6 pb-0"
                
              >
                <v-card
                  class="align-center my-auto"
                  rounded
                  color="transparent"
                  outlined
                  elevation="5"
                  min-height="150"
                  min-width="250"
                  
                >
                  <p class="px-4 pt-3 my-0">Név: {{ item.name }}</p>
                  <p class="px-4 my-0">
                    Munkaidő: {{ item.meddig - item.mettol }}
                  </p>
                  <p class="px-4 my-0">
                    időtartam: {{ item.mettol }} - {{ item.meddig }}
                  </p>
                  <p class="px-4 my-0">Munkaidő: {{ item.szabadsag }}</p>
                  <p class="px-4 my-0">
                    Munkaidő: {{ item.szabadsag_maradek }}
                  </p>
                </v-card>
              </v-col>
              <v-col
                :cols="12"
                :lg="6"
                :md="6"
                :sm="6"
                class="d-flex justify-end pt-6 pb-0"
              >
                <v-card
                  class="avatar text-center align-center my-auto"
                  rounded
                  outlined
                  elevation="5"
                  min-height="150"
                  color="transparent"
                >
                  <div class="align-center my-auto mt-6 mb-5">
                    <v-icon class="display-4" v-if="item.generalva">mdi-check</v-icon>
                    <v-icon class="display-4" v-else>mdi-close</v-icon>
                  </div>
                </v-card>
              </v-col>
            </v-row>
            <v-divider class="mx-4 my-3"></v-divider>
            <div class="mx-auto text-center">
              <v-btn
                small
                elevation="0"
                color="primary"
                class="white--text"
                :to="`/timesheet/${item.id}`"
                fab
              >
                <v-icon>mdi-printer-outline</v-icon>
              </v-btn>
              <p>Hónap nyomtatása</p>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <div class="text-center" style="opacity: 1">
      <v-dialog v-model="dialog" width="500" style="opacity: 1">
        <v-card style="opacity: 1" v-if="dialogType == 'modify'">
          <v-card-title class="text-h5 grey lighten-2">
            <h4 class="font" v-if="selectedDolgozo.id == 0">
              Új dolgozó felvétele
            </h4>
            <h4 class="font" v-else>Dolgozó módosítása</h4>
          </v-card-title>
          <v-container>
            <v-text-field label="Név" v-model="selectedDolgozo.name" />

            <v-text-field
              label="Munkaidő kezdete"
              type="number"
              v-model="selectedDolgozo.mettol"
            />
            <v-text-field
              label="Munkaidő vége"
              type="number"
              v-model="selectedDolgozo.meddig"
            />
            <v-text-field
              label="Szabadság"
              type="number"
              v-model="selectedDolgozo.szabadsag"
            />
            <v-text-field
              label="Maradék szabadság"
              type="number"
              v-model="selectedDolgozo.szabadsag_maradek"
            />
          </v-container>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="saveDolgozo"> Mentés </v-btn>
            <v-btn color="primary" text @click="dialog = false"> Mégse </v-btn>
          </v-card-actions>
        </v-card>
        <v-card style="opacity: 1" v-if="dialogType == 'berjegyzek'">
          <v-card-title class="text-h5 grey lighten-2">
            <h4 class="font">Bérjegyzék feltöltése</h4>
          </v-card-title>
          <v-container>
            <v-row class="mx-1 my-1 font"
              >Dolgozó neve: {{ selectedBerjegyzek.name }}</v-row
            >
            <v-row>
              <v-col :cols="12" :lg="6" :md="12">
                <v-select
                  label="Időszak kiválasztása"
                  :items="selectedBerjegyzek.idoszakok"
                  item-value="id"
                  item-text="idoszak"
                  v-model="selectedIdoszak"
                  :error-messages="errors.idoszak"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col :cols="12" :lg="6" :md="12">
                <v-file-input
                  label="File kiválasztása"
                  show-size
                  v-model="file"
                  :error-messages="errors.file"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="uploadFile"> Feltöltés </v-btn>
            <v-btn color="primary" text @click="dialog = false"> Mégse </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-overlay :value="loadingCircular" opacity="0.9">
      <v-progress-circular
        indeterminate
        size="250"
        width="10"
        color="uni_blue2"
      ></v-progress-circular>
    </v-overlay>
    <v-snackbar
      v-if="loading"
      :value="loading"
      :color="snackColor ? 'red' : 'green'"
      rounded="pill"
      bottom
      elevation="2"
      timeout="1000"
    >
      <p class="my-0" style="text-align: center">{{ snackMessage }}</p>
    </v-snackbar>
  </v-container>
</template>

<script>
import moment from 'moment';
// import { commonMixin } from '@/mixins/common.js';
export default {
  data: () => ({
    loadingCircular: false,
    snackColor: "",
    snackMessage: "",
    loading: false,
    dolgozok: [],
    dialog: false,
    selectedDolgozo: {},
    dialogType: false,
    file: null,
    selectedBerjegyzek: {},
    color: null,
    selectedIdoszak: null,
    errors: {},
    nyomtathato: true
  }),
  async mounted() {
    this.getDolgozok();
    console.log();
  },

  methods: {
    printing() {
      const printWindow = window.open("", "_blank");
      printWindow.document.write(
        "<html><head><title>Print</title></head><body>"
      );
      printWindow.document.write("Szia");
      printWindow.document.write("</body></html>");
      printWindow.document.close();
      printWindow.print();
    },
    upload(item) {
      this.dialogType = "berjegyzek";
      this.dialog = true;
      this.selectedBerjegyzek = item;
      this.errors = {};
    },
    async uploadFile() {
      try {
        this.errors = {};
        if (this.selectedIdoszak == null || !this.selectedIdoszak) {
          this.errors.idoszak = "Kötelező";
        }
        if (!this.file || this.file == null) {
          this.errors.file = "Kötelező";
        }
        if (Object.keys(this.errors).length == 0) {
          const formData = new FormData();
          formData.append("file", this.file);
          const response = await this.$http.post(
            "import/" + this.selectedBerjegyzek.id + "_" + this.selectedIdoszak,
            formData
          );

          this.snackMessage = response.message;
          this.snackColor = response.color;
          this.loading = setTimeout(3000);
        }
      } catch (e) {
        console.error(e);
      }
    },
    async getDolgozok() {
      try {
        const response = await this.$http.post("getDolgozok/0");
        this.dolgozok = response.dolgozok;
        this.dolgozok.push({
          id: 0,
          name: "",
          mettol: 0,
          meddig: 0,
          idoszak: "Nincs adat",
        });
        var nyomtathato = false;
        this.dolgozok.map((item) => {
          item.generalva = false;
          if (item.idoszak == null) {
            item.idoszak = "Nincs adat";
          } else {
            var utolso = item.idoszak.toLowerCase()
            var most = moment().subtract(1, 'month').format('YYYY-MMMM')
            console.log(utolso)
            console.log(most)
            if(utolso == most) {
              item.generalva = true
            } else {
              nyomtathato = true;
            }
          }
        });
      } catch (e) {
        console.error(e);
      }
    },
    async deleteDolgozo(item) {
      try {
        const response = await this.$http.post("deleteDolgozo/" + item.id);
        this.getDolgozok();
      } catch (e) {
        console.error(e);
      }
    },
    modifyDolgozo(item) {
      this.dialogType = "modify";
      this.dialog = true;
      this.selectedDolgozo = item;
    },
    addDolgozo() {
      this.dialogType = "modify";
      this.dialog = true;
      this.selectedDolgozo = this.dolgozok[this.dolgozok.length - 1];
    },
    async saveDolgozo() {
      try {
        if (
          this.selectedDolgozo.name != "" &&
          this.selectedDolgozo.mettol != 0 &&
          this.selectedDolgozo.meddig != 0 &&
          this.selectedDolgozo.szabadsag != 0 &&
          this.selectedDolgozo.szabadsag_maradek != 0
        ) {
          const response = await this.$http.post(
            "saveDolgozo/" + this.selectedDolgozo.id,
            this.selectedDolgozo
          );

          this.getDolgozok();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.dialog = false;
      }
    },
    async sornyomtat(){
      try {
        this.loadingCircular = true;
        const response = await this.$http.post('sornyomtat',{
          timeout: 0,
        });
        console.log(response)
        const link = document.createElement("a");
        link.href = "http://jelenleti.uninet.hu/api/tmp/" + response.file;
        link.setAttribute("target", "_blank");
        link.setAttribute("download", response.file + ".pdf");
        document.body.appendChild(link);
        link.click();
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingCircular = false;
      }
    }
  },
};
</script>
<style lang="scss">
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  position: absolute;
  width: 100%;
}
.font {
  font-family: monospace;
  font-size: large;
}
.show-btns {
  color: black !important;
}
.sticky {
  position: sticky;
  z-index: 5;
  top: 0;
  pointer-events: none;

  &.to-header {
    top: 2rem;
  }
  & > * {
    pointer-events: auto;
  }
}
.avatar {
  background-size: cover;
  border-radius: 5rem;
  background-position-y: 20%;
  height: 15vh;
  width: 15vh;
  object-fit: cover;
}


</style>
