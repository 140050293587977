<template>
  <div>
    <p class="display-1 mt-10 white--text">Szabadság kérés</p>
    <v-card class="pb-2 mb-2">
      <p class="pa-2 bgcolor">Szabadság kérés</p>
      <p class="pa-2">
        Felhasználható rendes szabadságok: {{ dolgozoSzabadsagok }}
      </p>
      <p class="pa-2">
        Felhasználható maradék szabadságok: {{ dolgozoMaradekSzabadsagok }}
      </p>
      <v-simple-table fixed-header height="350" class="text-center px-2">
        <thead>
          <tr class="border">
            <th class="border text-center">Dátum</th>
            <th class="border text-center">Megjegyzés</th>
            <th class="border text-center">Félnap</th>
            <th class="border text-center">Csaló nap</th>
            <th class="border text-center">Törlés</th>
          </tr>
        </thead>
        <tbody v-if="datesObjects.length > 0">
          <tr v-for="(date, id) in datesObjects" :key="id">
            <td class="bordered">{{ date.mettol }}</td>
            <td class="border">
              <v-text-field
                v-model="date.note"
                label="Megjegyzés"
              ></v-text-field>
            </td>
            <td class="border">
              <div class="flex">
                <v-switch
                  class=""
                  v-model="date.switch"
                  @change="felnapChange(date)"
                ></v-switch>
              </div>
            </td>
            <td class="border">
              <div class="flex">
                <v-switch
                  class=""
                  v-model="date.csalo"
                  @change="elosztasChange(date)"
                  :disabled="
                    dolgozoMaradekSzabadsagok == 0 && date.csalo == false
                  "
                ></v-switch>
              </div>
            </td>
            <td>
              <button @click="deleteDateObject(date, id)">
                <v-icon>mdi-delete</v-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            absolute
            color="green"
            class="white--text mr-15"
            fab
            right
            top
            v-on="on"
            v-bind="attrs"
            @click="szabadsagMentes"
            :disabled="datesObjects.length == 0 || More"
          >
            <v-icon>mdi-run</v-icon>
          </v-btn>
        </template>
        <span>Szabadság kérés</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            absolute
            color="green"
            class="white--text"
            fab
            right
            top
            v-on="on"
            v-bind="attrs"
            @click="dialogAdd = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>Hozzáadás</span>
      </v-tooltip>
    </v-card>

    <v-card class="pb-2 mb-2 mt-2">
      <p class="pa-2 bgcolor">Szabadság keresés</p>
      <v-row class="px-2">
        <v-col :cols="12" :lg="3" :md="3" :sm="12">
          <v-combobox
            label="Év"
            :items="searchYear"
            item-text="text"
            item-value="value"
            v-model="selectedYear"
            :error-messages="searchError"
          ></v-combobox>
        </v-col>
        <v-col :cols="12" :lg="3" :md="3" :sm="12">
          <v-combobox
            label="Hónap"
            :items="searchMonth"
            item-text="text"
            item-value="value"
            v-model="selectedMonth"
          ></v-combobox>
        </v-col>
        <v-col :cols="12" :lg="3" :md="3" :sm="12">
          <v-combobox
            label="Típus"
            :items="searchType"
            item-text="text"
            item-value="value"
            v-model="selectedType"
          ></v-combobox>
        </v-col>
        <v-col :cols="12" :lg="3" :md="3" :sm="12" class="my-auto">
          <v-btn class="my-auto" @click="searchSzabadsagok">Keresés</v-btn>
        </v-col>
      </v-row>
      <v-simple-table class="px-2">
        <thead>
          <tr>
            <th class="text-center">Dátum</th>
            <th class="text-center minwidth">Megjegyzés</th>
            <th class="text-center">Félnap</th>
            <th class="text-center">Könyvelt</th>
            <th class="text-center">Elfogadott</th>
            <th class="text-center">Módosítás / Törlés</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(szab, id) in szabadsagok" :key="id">
            <td class="text-center">{{ szab.mettol }}</td>
            <td class="text-center">{{ szab.megjegyzes }}</td>
            <td class="text-center">
              <v-icon v-if="szab.felnap == '1'">mdi-battery-50</v-icon>
              <v-icon v-else>mdi-battery</v-icon>
            </td>
            <td class="text-center">{{ szab.konyvelt_felnap }}</td>
            <td class="text-center">
              <v-icon v-if="szab.elfogadott == 1">mdi-check</v-icon>
              <v-icon v-else>mdi-close</v-icon>
            </td>
            <td class="text-center">
              <!-- <v-btn elevation="0" plain :outlined="false" > -->
              <!-- <v-icon>mdi-cog</v-icon> -->
              <button
                v-if="
                  new Date().setHours('00', '00', '00') <=
                    new Date(szab.mettol) &&
                  szab.konyvelt_felnap == null &&
                  szab.elfogadasra_var == '0'
                "
                @click="selectSzab(szab, 0)"
                class="mx-1"
              >
                <v-icon>mdi-cog</v-icon>
              </button>
              <button
                v-if="
                  new Date().setHours('00', '00', '00') <=
                    new Date(szab.mettol) &&
                  szab.konyvelt_felnap == null &&
                  szab.elfogadasra_var == '0'
                "
                @click="selectSzab(szab, 1)"
                class="mx-1"
              >
                <v-icon>mdi-delete</v-icon>
              </button>
              <!-- </v-btn> -->
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-snackbar :color="snackbarColor" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <div class="text-center">
      <v-dialog v-model="dialogModify" width="600">
        <v-card>
          <v-card-title class="ma-0 pa-0">
            <v-alert
              color="green darken-1"
              dark
              class="ma-0"
              min-width="600"
              rounded="0"
            >
              <v-icon class="mdi-36px mb-1">mdi-cog</v-icon>
              <span class="my-1">Szabadság módosítás</span>
            </v-alert>
          </v-card-title>
          <v-card-text class="justify-center text-center pa-0">
            <v-date-picker
              v-model="selectedSzab.mettol"
              first-day-of-week="1"
              :allowed-dates="allowedDates"
              width="600"
              class="rounded-0"
              elevation="2"
              :events="[selectedSzab.mettol]"
            ></v-date-picker>
            <v-divider></v-divider>
            <v-text-field
              class="ma-3"
              label="Megjegyzés"
              v-model="selectedSzab.megjegyzes"
            ></v-text-field>
            <v-select
              class="ma-3"
              label="Típus"
              :items="tipusok"
              item-value="value"
              item-text="text"
              v-model="selectedSzab.felnap"
            ></v-select>
            <p v-if="selectedSzab.elfogadott == 0">
              A szabadságkérelem módosulni fog
            </p>
            <p v-if="selectedSzab.elfogadott == 1" class="mb-0 mt-2">
              Mivel ez egy elfogadott szabadság a <b>Főnök Úr</b>nak el kell
              fogadnia.
            </p>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions class="justify-end">
            <v-btn @click="modifySzabReq">Módosítás</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" width="500">
        <v-card>
          <v-card-title class="ma-0 pa-0">
            <v-alert color="red darken-4" dark class="ma-0" min-width="500">
              <v-icon class="mdi-36px mb-1">mdi-delete</v-icon>
              <span class="my-1">Figyelem!</span>
            </v-alert>
          </v-card-title>
          <v-card-text class="justify-center text-center mt-3">
            <p class="display-1 text-center mb-0">
              Törölni kívánja a szabadságot?
            </p>
            <p v-if="selectedSzab.elfogadott == 0">
              A szabadságkérelem törlődni fog
            </p>
            <p v-if="selectedSzab.elfogadott == 1" class="mb-0 mt-2">
              Mivel ez egy elfogadott szabadság a <b>Főnök Úr</b>nak el kell
              fogadnia.
            </p>
          </v-card-text>
          <v-divider class="mx-4"></v-divider>
          <v-card-actions class="justify-end">
            <v-btn @click="deleteSzabReq">Törlés</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogAdd" width="600" persistent>
        <v-card elevation="0" :outlined="false">
          <v-card-text class="pa-0">
            <v-date-picker
              v-model="dates"
              multiple
              first-day-of-week="1"
              :allowed-dates="allowedDates"
              width="600"
            ></v-date-picker>
            <v-row class="ma-0 pa-0">
              <v-col :cols="12">
                <v-btn
                  color="green"
                  class="white--text my-3 mx-1 float-right"
                  @click="getszabadsagokCheck"
                  >Hozzáad</v-btn
                >
                <v-btn
                  color="green"
                  class="white--text my-3 mx-1 float-right"
                  @click="deleteDates"
                  >Mégse</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    seged: null,
    dialogAdd: false,
    dialogModify: false,
    dialogDelete: false,
    snackbar: false,
    snackbarText: "",
    snackbarColor: "green",
    dates: [],
    datesObjects: [],
    notes: [],
    switches: [],
    searchYear: [],
    searchMonth: [
      {
        value: "01",
        text: "Január",
      },
      {
        value: "02",
        text: "Február",
      },
      {
        value: "03",
        text: "Március",
      },
      {
        value: "04",
        text: "Április",
      },
      {
        value: "05",
        text: "Május",
      },
      {
        value: "06",
        text: "Június",
      },
      {
        value: "07",
        text: "Július",
      },
      {
        value: "08",
        text: "Augusztus",
      },
      {
        value: "09",
        text: "Szeptember",
      },
      {
        value: "10",
        text: "Október",
      },
      {
        value: "11",
        text: "november",
      },
      {
        value: "12",
        text: "December",
      },
    ],
    searchType: [
      {
        value: 1,
        text: "Összes",
      },
      {
        value: 2,
        text: "Fél nap",
      },
      {
        value: 3,
        text: "Teljes nap",
      },
    ],
    selectedYear: {
      value: "",
      text: "",
    },
    selectedMonth: {
      value: "",
      text: "",
    },
    selectedType: {
      value: "",
      text: "",
    },
    tipusok: [
      {
        value: "0",
        text: "Teljes nap",
      },
      {
        value: "1",
        text: "Fél nap",
      },
    ],
    searchError: "",
    szabadsagok: [],
    selectedSzab: {},
    AllAllowedDates: [],
    dolgozoSzabadsagok: 0,
    dolgozoMaradekSzabadsagok: 0,
  }),

  computed: {
    More() {
      return this.dolgozoSzabadsagok < 0 || this.dolgozoMaradekSzabadsagok < 0
    }
  },

  beforeMount() {
    var currentYear = new Date().getFullYear(),
      years = [];
    var yearFrom = parseInt(currentYear);
    var yearTo = parseInt(currentYear) - 10;
    var counter = 0;
    for (let index = yearFrom; index >= yearTo; index--) {
      var year = {
        value: counter,
        text: index.toString(),
      };
      this.searchYear.push(year);
      counter++;
    }
  },

  mounted() {
    this.getAllowedDates();
    this.getDolgozoSzabadsagok();
    this.searchSzabadsagok();
  },

  methods: {
    felnapChange(item) {
      if (item.csalo == true) {
        //20 / 2
        if (item.switch == true) {
          this.dolgozoMaradekSzabadsagok =
            parseFloat(this.dolgozoMaradekSzabadsagok) + 0.5;
        } else {
          this.dolgozoMaradekSzabadsagok =
            parseFloat(this.dolgozoMaradekSzabadsagok) - 0.5;
        }
      } else {
        if (item.switch == true) {
          this.dolgozoSzabadsagok = parseFloat(this.dolgozoSzabadsagok) + 0.5;
        } else {
          console.log("kikapcsoltam");
          this.dolgozoSzabadsagok = parseFloat(this.dolgozoSzabadsagok) - 0.5;
        }
      }
    },

    elosztasChange(item) {
      console.log("elosztas");
      if (item.switch == true) {
        if (item.csalo == true) {
          //true / true
          this.dolgozoSzabadsagok = parseFloat(this.dolgozoSzabadsagok) + 0.5;
          this.dolgozoMaradekSzabadsagok =
            parseFloat(this.dolgozoMaradekSzabadsagok) - 0.5;
        } else {
          // true / false
          this.dolgozoSzabadsagok = parseFloat(this.dolgozoSzabadsagok) - 0.5;
          this.dolgozoMaradekSzabadsagok =
            parseFloat(this.dolgozoMaradekSzabadsagok) + 0.5;
        }
      } else {
        if (item.csalo == true) {
          // false / true
          this.dolgozoSzabadsagok++;
          this.dolgozoMaradekSzabadsagok--;
        } else {
          //false / false
          this.dolgozoSzabadsagok--;
          this.dolgozoMaradekSzabadsagok++;
        }
      }
    },

    async getDolgozoSzabadsagok() {
      try {
        const response = await this.$http.post("getDolgozoSzabadsagok");
        this.dolgozoSzabadsagok = response.szabadsagok.szabadsag;
        this.dolgozoMaradekSzabadsagok = response.szabadsagok.szabadsag_maradek;
      } catch (e) {
        console.error(e);
      }
    },

    async getAllowedDates() {
      try {
        const post = {
          id: this.$store.state.user.id,
        };
        const response = await this.$http.post("/getAllowedDates", post);
        this.AllAllowedDates = response.szabik;
      } catch (e) {
        console.error(e);
      }
    },

    allowedDates(val) {
      var napok = [];
      var date = new Date();
      var hetvege = new Date(val).getDay() > 0 && new Date(val).getDay() < 6;
      var kisebbnapok = new Date(val) >= new Date(); //.setHours("00", "00", "00");
      var elerheto = new Date(val);
      var elerheto_vissza = new Date(val);
      switch (new Date().getDay()) {
        case 1:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() + 2));
          break;
        case 2:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() + 2));
          break;
        case 3:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() + 2));
          break;
        case 4:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() + 4));
          break;
        case 5:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() + 4));
          break;
        case 6:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() + 3));
          break;
        case 0:
          elerheto_vissza =
            elerheto_vissza >= new Date(date.setDate(date.getDate() + 2));
          break;

        default:
          break;
      }
      return hetvege && elerheto_vissza && elerheto && kisebbnapok;
      // return (
      //   new Date(val).getDay() > 0 &&
      //   new Date(val).getDay() < 6 &&
      //   new Date(val) >= new Date().setHours("00", "00", "00") &&
      //   !this.AllAllowedDates.includes(val)
      // );
    },

    async getszabadsagokCheck() {
      try {
        if(this.dates.length > this.dolgozoSzabadsagok) {
          //Azt kell nézni, hogy van-e annyi szabid, mint amennyit kijelöltél 
          //a mentés előtt meg kéne nézni, hogy nem lehet elmenteni - számot.
        }
        this.snackbar = false;
        var post = {
          dates: this.dates,
          user_id: this.$store.state.user.id,
        };
        const response = await this.$http.post("/getSzabadsagokCheck", post);
        this.snackbarText = "Eze(ke)n a napo(ko)n már van mentve szabadság: ";
        if (response.van) {
          response.szabadsagok.forEach((element) => {
            this.snackbarText += " " + element.mettol;
          });
          this.snackbar = true;
        } else {
          var van = false;
          var letezo = [];
          this.datesObjects.forEach((element) => {
            this.dates.forEach((item) => {
              if (item == element.mettol) {
                van = true;
                letezo.push(item);
              }
            });
          });
          if (!van) {
            this.dates.forEach((element) => {
              var item = {};
              item.mettol = element;
              item.note = "";
              item.switch = false;
              item.csalo = false;
              this.datesObjects.push(item);
              this.dolgozoSzabadsagok--;
            });
            this.dialogAdd = false;
            this.dates = [];
          } else {
            this.snackbarText =
              "Eze(ke)n a napo(ko)n már van mentve szabadság: ";
            letezo.forEach((element) => {
              this.snackbarText += " " + element;
            });
            this.snackbar = true;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    async szabadsagMentes() {
      try {
        const post = {
          dates: this.datesObjects,
          user_id: this.$store.state.user.id,
        };
        const response = await this.$http.post("/szabadsagMentes", post);
        if (response.status == "OK") {
          this.snackbarColor = "green";
          this.snackbarText = "Sikeres mentés";
          this.deleteDates();
        }
      } catch (e) {
        this.snackbarColor = "red";
        this.snackbarText = "Hiba történt";
        console.error(e);
      } finally {
        this.snackbar = true;
        this.getAllowedDates();
        this.datesObjects = [];
        // this.getDolgozoSzabadsagok();
      }
    },

    deleteDates() {
      this.dates = [];
      this.dialogAdd = false;
    },

    deleteDateObject(item, id) {
      this.datesObjects.splice(id, 1);
      if (item.csalo == true) {
        this.dolgozoMaradekSzabadsagok++;
      } else {
        this.dolgozoSzabadsagok++;
      }
    },

    async searchSzabadsagok() {
      try {
        this.searchError = "";
        if (this.selectedMonth == null || this.selectedMonth == "") {
          this.selectedMonth = {
            value: "",
            text: "",
          };
        }
        if (this.selectedYear == null || this.selectedYear == "") {
          this.selectedYear = {
            value: "",
            text: "",
          };
        }
        if (this.selectedType == null || this.selectedType == "") {
          this.selectedType = {
            value: "",
            text: "",
          };
        }
        const post = {
          year: this.selectedYear,
          month: this.selectedMonth,
          type: this.selectedType,
          user_id: this.$store.state.user.id,
        };
        if (this.selectedMonth.text != "" && this.selectedYear.text == "") {
          this.searchError =
            "Ha hónap van választva, akkor évet is kell választani";
        } else {
          const response = await this.$http.post("/searchSzabadsagok", post);
          this.szabadsagok = response.szabadsagok;
          if (this.szabadsagok.length == 0) {
            this.snackbarText = "Nincs eredmény";
            this.snackbarColor = "red";
            this.snackbar = true;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    selectSzab(item, id) {
      this.selectedSzab = item;
      if (id == 0) {
        this.dialogModify = true;
      } else {
        this.dialogDelete = true;
      }
    },

    async deleteSzabReq() {
      try {
        const response = await this.$http.post(
          "/deleteSzabReq",
          this.selectedSzab
        );
        if (response.status == "OK") {
          this.snackbarText =
            this.selectedSzab.elfogadott == "0"
              ? "Sikeres törlés"
              : "Sikeres jelzés";
          this.snackbarColor = "green";
          if (this.selectedSzab.elfogadott == "1") {
            this.szabadsagok.forEach((item) => {
              if (item.id == this.selectedSzab.id) {
                item.elfogadott == "0";
              }
            });
          }
        } else {
          this.snackbarText = "Hiba történt!";
          this.snackbarColor = "red";
        }
      } catch (e) {
        console.error(e);
        this.snackbarText = "Hiba történt!";
        this.snackbarColor = "red";
      } finally {
        this.searchSzabadsagok();
        this.getAllowedDates();
        this.snackbar = true;
        this.dialogDelete = false;
      }
    },

    async modifySzabReq() {
      try {
        const response = await this.$http.post(
          "/modifySzabReq",
          this.selectedSzab
        );
        if (response.status == "OK") {
          this.snackbarText =
            this.selectedSzab.elfogadott == "0"
              ? "Sikeres Módosítás"
              : "Sikeres jelzés";
          this.snackbarColor = "green";
          if (this.selectedSzab.elfogadott == "1") {
            this.szabadsagok.forEach((item) => {
              if (item.id == this.selectedSzab.id) {
                item.elfogadott == "0";
              }
            });
          }
        }
      } catch (e) {
        console.error(e);
        this.snackbarText = "Hiba történt!";
        this.snackbarColor = "red";
      } finally {
        this.searchSzabadsagok();
        this.getAllowedDates();
        this.snackbar = true;
        this.dialogModify = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.v-btn::before {
  background-color: transparent !important;
}
.v-input--switch:hover {
  color: transparent !important;
}
.v-input--is-dirty:hover {
  color: transparent;
}
.flex {
  display: flex !important;
  justify-content: center !important;
}
.minwidth {
  min-width: 450px !important;
}
</style>