<template>
  <div>
    <v-card class="my-1" color="transparent" elevation="0">
      <v-card-title class="white--text"
        >Elfogadásra váró jelenléti ívek</v-card-title
      >
      <v-row class="px-3">
        <v-col
          v-for="(item, id) in opened"
          :key="id"
          class="my-2"
          :cols="12"
          :lg="4"
          :md="6"
          :sm="12"
        >
          <v-hover v-slot="{ hover }" v-if="item.id != 0">
            <v-card
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              height="210"
            >
              <v-card-title class="justify-center">{{
                item.idoszak
              }}</v-card-title>
              <v-container class="px-6"><v-divider></v-divider></v-container>
              <v-container class="py-0 my-0">
                <v-row class="align-center my-auto">
                  <v-btn
                    elevation="0"
                    width="70"
                    height="70"
                    color="green"
                    class="white--text mx-auto my-3 btnhover"
                    fab
                    @click="selectItem(item)"
                  >
                    <v-icon class="">mdi-check</v-icon>
                  </v-btn>
                  <!-- <button class="button" @click="selectItem(item)"><v-icon dark>mdi-check</v-icon></button> -->
                  <!--itt az elfogadod kell átírni, hogy az itemet be kell tenni valamibe hogy tovább tudjam küldeni az idját-->
                </v-row>
              </v-container>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-card>
    <div class="text-center">
      <v-dialog v-model="elfogad" width="800">
        <v-card class="m-0 pa-5">
          <v-card class="pa-5">
            <v-simple-table>
              <thead>
                <tr>
                  <th>Nap</th>
                  <th>Érkezés</th>
                  <th>Távozás</th>
                  <th>Ledolgozott óra</th>
                  <th>távollét oka</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, id) in timesheet" :key="id">
                  <td>{{ date + "-" + item.nap }}</td>
                  <td>{{ item.erkezes }}</td>
                  <td>{{ item.tavozas }}</td>
                  <td>{{ item.ledolgozott_ora }}</td>
                  <td>{{ item.tavollet_oka }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
          <v-card-title class="justify-center"
            ><button class="border">
              <v-icon color="green" class="display-4">mdi-check</v-icon>
            </button></v-card-title
          >
          <v-card-text class="text-center display-1"
            >Biztosan elfogadod a jelenléti ívedet?</v-card-text
          >
          <v-divider class="mx-4"></v-divider>
          <v-card-actions class="justify-end white--text"
            ><v-btn dark color="green" @click="elfogadTimesheet()"
              >Elfogadás</v-btn
            ></v-card-actions
          >
        </v-card>
      </v-dialog>
      <v-snackbar :color="snackbarColor" v-model="snackbar">{{
        snackbarText
      }}</v-snackbar>
    </div>
  </div>
</template>

<script>
// import {CommonMixin} from '@/mixins/common.js'
export default {
  // mixins: [
  //   CommonMixin
  // ],
  components: {},
  data() {
    return {
      opened: [],
      elfogad: false,
      selectedItem: {},
      timesheet: [],
      date: "",
      snackbarColor: "green",
      snackbarText: "",
      snackbar: false,
    };
  },
  async mounted() {
    this.getOpened();
  },
  methods: {
    async getOpened() {
      try {
        this.opened = [];
        var post = {
          id: this.$store.state.user.id,
        };
        const response = await this.$http.post("/getOpenedTimesheet", post);
        this.opened = response.opened;
        console.log(response);
      } catch (e) {
        console.error(e);
      }
    },
    async selectItem(item) {
      try {
        this.selectedItem = item;
        var date = this.selectedItem.idoszak.split("-");
        var month = [];
        month["Január"] = "01";
        month["Február"] = "02";
        month["Március"] = "03";
        month["Április"] = "04";
        month["Május"] = "05";
        month["Június"] = "06";
        month["Július"] = "07";
        month["Augusztus"] = "08";
        month["Szeptember"] = "09";
        month["Október"] = "10";
        month["November"] = "11";
        month["December"] = "12";
        date[0] = parseInt(date[0]);
        date[1] = month[date[1]];
        this.date = date[0] + "-" + date[1];
        const response = await this.$http.post("/getOTS", item);
        this.timesheet = response.timesheet;
      } catch (e) {
        console.error(e);
      } finally {
        this.elfogad = true;
      }
    },
    async elfogadTimesheet() {
      try {
        console.log(this.selectedItem);
        var post = {
          user_id: this.$store.state.user.id,
          idoszak: this.selectedItem.id,
        };
        const response = await this.$http.post("/elfogadTimesheet", post);
        if (response.status === "OK") {
          this.snackbarColor = "green";
          this.snackbarText = "Sikeres elfogadás";
        }
        this.snackbar = true;
        this.elfogad = false;
        this.getOpened();
      } catch (e) {
        console.error();
      }
    },
  },
};
</script>

<style lang="scss">
.btnhover {
  background: linear-gradient(135deg, #0891b2 20%, #32bf03);
  background-repeat: no-repeat;
  opacity: 1;
  transition: 1.5s;
}
</style>

