import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from 'vue-cookies';
import http from "@/http";
// import Google from 'googleapis'

Vue.use(VueCookies);
// Vue.use(Google, {
//   apiKey: "AIzaSyALh9BE-wkdJ9Kr92C24VMkAtPUDpPd7FI",
//   clientId: '164298016433-5at93rltrujseer0s4a2agk0pf20tfp8.apps.googleusercontent.com',
//   discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//   scope: 'https://www.googleapis.com/auth/calendar',
// })

Vue.config.productionTip = false;
global.moment = require('moment');
global.moment.locale('hu')
Vue.prototype.$config = {
  header: {
    height: 60
  },
  sidebar: {
    width: 260
  },
  footer: {
    height: 60
  }
};
window.vue =new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
